<template>
  <dx-modal title="Gerenciar conta convênio/particular"
            id="modal-particular-account" size="lg"
            :value="show" @input="close(false)">
    <div class="loading loading-lg" v-if="loading" />
    <div class="particular-account" v-else>
      <template v-if="finished || closed">
        <div class="cashier-message mb-1" v-if="data.cashier">
          <strong>
            <fa-icon class="mr-1" :icon="['fal', 'info-circle']" />
            <span>Status: {{ getName(data.cashier.status) }}</span>
            <span class="ml-2">
              Abertura: {{ data.cashier.openingDate | date }}
            </span>
            <span class="ml-2">
              Movimentação: {{ data.cashier.dateTime | date }}
            </span>
            <span class="ml-2" v-if="data.cashier.closingDate">
              Fechamento: {{ data.cashier.closingDate | date }}
            </span>
            <span class="ml-2" v-if="data.cashier.user">
              Usuário: {{ data.cashier.user.name }}
            </span>
          </strong>
        </div>
        <div class="text-warning text-bold" v-if="finished">
          * Essa conta já foi finalizada. Por esse motivo não será possível editá-la!
        </div>
        <div class="text-warning text-bold" v-else-if="closed">
          * Este caixa já foi finalizado. Por esse motivo não será possível editar a conta!
        </div>
        <div class="columns">
          <div class="column col-4 col-sm-12 form-group">
            <label class="form-label">Paciente</label>
            <input type="text" class="form-input text-bold"
                   v-model="form.patient.name" readonly>
          </div>
          <div class="column col-4 col-sm-12 form-group">
            <label class="form-label">Tipo</label>
            <input type="text" class="form-input text-bold"
                   v-model="form.patient.insurance.name" readonly>
          </div>
          <div class="column col-4 form-group">
            <label class="form-label">Fatura</label>
            <input type="text" class="form-input text-bold"
                   v-model="form.invoice.code" readonly>
          </div>
        </div>
        <h6 class="h6">Dados do Atendimento</h6>
        <div class="columns">
          <div class="column col-3 form-group">
            <label class="form-label">Número da guia</label>
            <input type="text" class="form-input" readonly
                   v-model="form.referral.provider">
          </div>
          <div class="column col-3 form-group">
            <label class="form-label">Tipo do atendimento</label>
            <input type="text" class="form-input" readonly
                   :value="serviceTypes[form.serviceType]">
          </div>
          <div class="column col-3 form-group">
            <label class="form-label">Tipo da consulta</label>
            <input type="text" class="form-input" readonly
                   :value="consultationTypes[form.consultationType]">
          </div>
          <div class="column col-3 form-group">
            <label class="form-label">Tipo da guia</label>
            <input type="text" class="form-input" readonly
                   v-model="form.referral.type">
          </div>
        </div>
        <h6 class="h6">Forma de pagamento</h6>
        <table class="table table-hover">
            <thead>
            <tr>
              <th style="width: 30px;">#</th>
              <th>Conta</th>
              <th>Forma de pagamento</th>
              <th class="text-center">Vencimento</th>
              <th class="text-right">Desconto</th>
              <th class="text-right">Juro</th>
              <th class="text-right">Taxa</th>
              <th class="text-right">Valor</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(installment, i) in form.installments" :key="i">
              <td>{{ installment.method.installment.groupId }}</td>
              <td>{{ installment.bankAccount.name }}</td>
              <td>
                {{ installment.method.name }}
                <small v-if="installment.method.type === 'credit_card'">
                  ({{ installment.method.installment.current }})
                </small>
              </td>
              <td class="text-center">{{ formatDate(installment.dueDate) }}</td>
              <td class="text-right">{{ installment.discount | currency }}</td>
              <td class="text-right">{{ installment.interest | currency }}</td>
              <td class="text-right">{{ installment.rate | currency }}</td>
              <td class="text-right">{{ installment.value | currency }}</td>
            </tr>
            </tbody>
          </table>
        <div class="h6 mt-2">Procedimentos</div>
        <table class="table table-striped">
          <thead>
          <tr>
            <th>#</th>
            <th>Data</th>
            <th>Código</th>
            <th>Nome</th>
            <th>Profissional</th>
            <th class="text-center">Quantidade</th>
            <th class="text-right">Total</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(expense, i) in form.expenses" :key="i">
            <td>{{ i + 1 }}</td>
            <td>{{ expense.startDate | date }}</td>
            <td>{{ expense.code | tuss }}</td>
            <td>{{ expense.name }}</td>
            <td>{{ expense.professional ? expense.professional.name : '-' }}</td>
            <td class="text-center">{{ expense.quantity }}</td>
            <td class="text-right">{{ expense.value.total | currency }}</td>
          </tr>
          </tbody>
        </table>
      </template>
      <template v-else>
        <div class="stenci-title">Atendimento</div>
        <div class="columns">
          <div class="column col-5 col-sm-12">
            Paciente: <strong>{{ form.patient.name }}</strong>
          </div>
        </div>
        <div class="divider"/>
        <div class="stenci-title">Dados do Atendimento</div>
        <div class="columns form-group">
          <div class="column col-3 form-group"
               :class="{'has-error': $v.form.referral.provider.$error}">
            <label for="referral-code" class="form-label">Número da guia</label>
            <input type="text" id="referral-code" class="form-input" maxlength="20"
                   :disabled="planSettings.providerReferral.mode !== 'manual'"
                   v-model="form.referral.provider" placeholder="000">
            <template v-if="$v.form.referral.provider.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.referral.provider.required">Campo obrigatório</div>
            </template>
          </div>
          <div class="column col-3 form-group"
               :class="{'has-error': $v.form.serviceType.$error}">
            <label class="form-label">Tipo do atendimento</label>
            <select id="referral-service-type" class="form-select"
                    v-model="form.serviceType" @blur="$v.form.serviceType.$touch()"
                    @change="clearConsultation">
              <option v-for="(text, value) in serviceTypes"
                      :value="value" :key="value">{{ text }}</option>
            </select>
            <template v-if="$v.form.serviceType.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.serviceType.required">
                Campo obrigatório
              </div>
            </template>
          </div>
          <div class="column col-3 form-group"
               :class="{'has-error': $v.form.consultationType.$error}">
            <label class="form-label">Tipo da consulta</label>
            <select id="consultation-type" class="form-select"
                    v-model="form.consultationType"
                    :disabled="form.serviceType !== 'consultation'"
                    @blur="$v.form.consultationType.$touch()">
              <option value=""></option>
              <option v-for="(text, value) in consultationTypes"
                      :value="value" :key="value">{{ text }}</option>
            </select>
            <template v-if="$v.form.consultationType.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.consultationType.required">
                Campo obrigatório
              </div>
            </template>
          </div>
          <div class="column col-3 form-group"
               :class="{'has-error': $v.form.referral.type.$error}">
            <label class="form-label">
              Tipo da guia
            </label>
            <select id="referral-type" class="form-select"
                    @blur="$v.form.referral.type.$touch()"
                    v-model="form.referral.type">
              <option value="">Selecione</option>
              <option value="consultation">Consulta</option>
              <option value="sadt">SP/SADT</option>
              <option value="particular">Particular</option>
            </select>
            <template v-if="$v.form.referral.type.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.referral.type.required">Campo obrigatório</div>
            </template>
          </div>
        </div>
        <div v-if="service.particular" class="divider"/>
        <div v-if="service.particular" class="stenci-title">Dados do Recebimento</div>
        <div v-if="service.particular" class="columns">
          <div class="column col-12 mt-2">
            <label class="form-label">Modalidade</label>
            <label class="form-radio form-inline">
              <input type="radio" name="modality" v-model="payment.cashier.modality"
                     value="company" @change="changeModality"
                     :disabled="payment.cashier.status === 'closed'">
              <i class="form-icon"/> Recebimento efetuado pela clínica
            </label>
            <label class="form-radio form-inline">
              <input type="radio" name="modality" v-model="payment.cashier.modality"
                     @change="changeModality" value="professional"
                     :disabled="payment.cashier.status === 'closed'">
              <i class="form-icon"/> Recebimento efetuado pelo profissional
            </label>
          </div>
          <div class="column col-12 mt-2" v-if="payment.cashier.modality === 'company'">
            <label class="form-switch">
              <input type="checkbox" v-model="payment.enabled"
                     :disabled="payment.cashier.status === 'closed'"
              />
              <i class="form-icon"/> Efetuar o recebimento
            </label>
          </div>
          <div v-if="payment.cashier.modality === 'professional'" class="payment-body column">
            <div class="empty">
              <div class="empty-icon">
                <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
              </div>
              <div class="empty-subtitle">
                O paciente irá realizar o pagamento do atendimento dentro do consultório
              </div>
            </div>
          </div>
          <div v-if="payment.cashier.modality === 'company'
          && payment.cashiers.length === 0" class="payment-body column">
            <div class="empty">
              <div class="empty-icon">
                <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
              </div>
              <div class="empty-subtitle">
                Nenhum caixa aberto para dar sequência no recebimento do atendimento
              </div>
            </div>
          </div>
          <div v-else-if="payment.cashier.modality === 'company' && payment.enabled === false"
               class="payment-body column">
            <div class="empty">
              <div class="empty-icon">
                <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
              </div>
              <div class="empty-subtitle">
                O paciente deverá ser encaminhado para a tesouraria para realizar o pagamento
              </div>
            </div>
          </div>
        </div>
        <div v-if="service.particular && payment.enabled && payment.cashiers.length > 0"
             class="columns">
          <div class="column col-4 col-sm-12 form-group"
               :class="{'has-error': $v.payment.cashier.id.$error}">
            <label class="form-label">Caixa</label>
            <select id="bank-account" v-model="payment.cashier.id" class="form-select"
                    @focus="$v.payment.cashier.id.$touch()"
                    @change="changeCashier"
                    :disabled="form.installments.length > 0">
              <option value="">[Selecione]</option>
              <option v-for="item in payment.cashiers" :value="item.id" :key="item.id">
                {{ item.bankAccount.name }}
              </option>
            </select>
          </div>
          <div class="column col-5 col-sm-12 form-group"
               :class="{'has-error': $v.payment.cashier.category.id.$error}">
            <label class="form-label">Categoria</label>
            <select id="cashier-category" v-model="payment.cashier.category.id" class="form-select"
                    @change="$v.payment.cashier.category.id.$touch()"
                    :disabled="payment.cashier.status === 'closed'">
              <option value="">[Selecione]</option>
              <option v-for="item in payment.categories" :value="item.id" :key="item.id">
                {{ item.name }}
              </option>
            </select>
            <template v-if="$v.payment.cashier.category.id.$error">
              <div class="form-input-hint"
                   v-if="!$v.payment.cashier.category.id.required">Campo obrigatório
              </div>
            </template>
          </div>
          <div class="column col-3 col-sm-12 form-group">
            <label class="form-label">Total</label>
            <dx-input-number id="total" class="form-input text-right text-bold"
                             v-model="particularTotal" maxlength="7" readonly
                             :disabled="payment.cashier.status === 'closed'"
                             :value="particularTotal" :precision="2"/>
          </div>
          <div class="column col-12" v-if="data.cashier">
            <div class="cashier-message">
              <strong>
                <fa-icon class="mr-1" :icon="['fal', 'info-circle']" />
                <span>Status: {{ getName(data.cashier.status) }}</span>
                <span class="ml-2">
                Abertura: {{ data.cashier.openingDate | date }}
              </span>
                <span class="ml-2">
                Movimentação: {{ data.cashier.dateTime | date }}
              </span>
                <span class="ml-2" v-if="data.cashier.closingDate">
                Fechamento: {{ data.cashier.closingDate | date }}
              </span>
                <span class="ml-2" v-if="data.cashier.user">
                Usuário: {{ data.cashier.user.name }}
              </span>
              </strong>
            </div>
          </div>
          <div class="column col-12 mt-2">
            <label class="form-label">Fonte pagadora</label>
            <label class="form-radio form-inline">
              <input type="radio" name="type" v-model="payment.source.type" value="person"
                     :disabled="payment.cashier.status === 'closed'" />
              <i class="form-icon"/> Pessoa Física
            </label>
            <label class="form-radio form-inline">
              <input type="radio" name="type" v-model="payment.source.type" value="company"
                     :disabled="payment.cashier.status === 'closed'" />
              <i class="form-icon"/> Pessoa Jurídica
            </label>
          </div>
          <div class="column col-3" v-if="payment.source.type === 'person'"
               :class="{'has-error': $v.payment.source.value.$error}">
            <label for="company-cpf" class="form-label">CPF</label>
            <input class="form-input" type="text" id="company-cpf" name="company-cpf"
                   key="company-cpf" v-model="payment.source.value"
                   @change="loadEntity" @focus="$v.payment.source.value.$touch()"
                   autocomplete="nope" placeholder="000.000.000-00" v-mask-cpf
                   :disabled="payment.cashier.status === 'closed'"/>
            <template v-if="$v.payment.source.value.$error">
              <div class="form-input-hint"
                   v-if="!$v.payment.source.value.cpf">CPF inválido
              </div>
            </template>
          </div>
          <div class="column col-3" v-else
               :class="{'has-error': $v.payment.source.value.$error}">
            <label for="company-cnpj" class="form-label">CNPJ</label>
            <input type="text" id="company-cnpj" name="company-cnpj" key="company-cnpj"
                   @change="loadEntity" @focus="$v.payment.source.value.$touch()"
                   class="form-input" autocomplete="nope" placeholder="00.000.000/0000-00"
                   v-model="payment.source.value" v-mask-cnpj
                   :disabled="payment.cashier.status === 'closed'" />
            <template v-if="$v.payment.source.value.$error">
              <div class="form-input-hint"
                   v-if="!$v.payment.source.value.cnpj">CNPJ inválido
              </div>
            </template>
          </div>
          <div class="column col-6 col-sm-12 form-group">
            <label for="source-name" class="form-label">Nome</label>
            <input id="source-name" class="form-input" v-model="payment.source.name"
                   :disabled="payment.cashier.status === 'closed'" />
          </div>
          <div class="column col-3 col-sm-12 form-group">
            <label for="document" class="form-label">Número documento</label>
            <input id="document" class="form-input" v-model="payment.document"
                   :disabled="payment.cashier.status === 'closed'"/>
          </div>
          <div class="column col-3 col-sm-12 form-group"
               :class="{'has-error': $v.payment.discount.value.$error}">
            <label class="form-label">Desconto</label>
            <div class="input-group">
              <select class="form-select" v-model="payment.discount.type"
                      style="max-width: 2.5rem; background-color: #f8f9fa"
                      :disabled="form.installments.length > 0
                    || form.courtesy || !allowDiscountInterest">
                <option value="percent">%</option>
                <option value="value">R$</option>
              </select>
              <dx-input-number id="discount-percent"
                               class="form-input text-right"
                               @change="calculateDiscountPercentValue"
                               :precision="2" maxlength="6"
                               :disabled="form.installments.length > 0
                             || form.courtesy
                             || !allowDiscountInterest"
                               v-model="payment.discount.percent"
                               v-if="payment.discount.type === 'percent'" />
              <dx-input-number id="discount-value"
                               class="form-input text-right"
                               @focus="$v.payment.discount.value.$touch()"
                               @change="calculateDiscountPercentValue"
                               :precision="2" maxlength="10"
                               v-model="payment.discount.value"
                               :disabled="form.installments.length > 0
                             || form.courtesy
                             || payment.discount.type === 'percent'
                             || !allowDiscountInterest" />
            </div>
          </div>
          <div class="column col-3 col-sm-12 form-group">
            <label class="form-label">Juros</label>
            <div class="input-group">
              <select class="form-select" v-model="payment.interest.type"
                      style="max-width: 2.5rem; background-color: #f8f9fa"
                      :disabled="form.installments.length > 0
                    || form.courtesy
                    || !allowDiscountInterest">
                <option value="percent">%</option>
                <option value="value">R$</option>
              </select>
              <dx-input-number id="interest-percent"
                               class="form-input text-right"
                               @change="calculateInterestPercentValue"
                               :precision="2" maxlength="6"
                               :disabled="form.installments.length > 0
                             || form.courtesy
                             || !allowDiscountInterest"
                               v-model="payment.interest.percent"
                               v-if="payment.interest.type === 'percent'" />
              <dx-input-number id="interest-value"
                               class="form-input text-right"
                               @change="calculateInterestPercentValue"
                               :precision="2" maxlength="10"
                               v-model="payment.interest.value"
                               :disabled="form.installments.length > 0
                             || form.courtesy
                             || payment.interest.type === 'percent'
                             || !allowDiscountInterest" />
            </div>
          </div>
          <div class="column col-3 col-sm-12 form-group">
            <label class="form-label">Taxa</label>
            <dx-input-number id="rate" class="form-input text-right" readonly
                             :value="rateTotal" :disabled="true" :precision="2" maxlength="7"/>
          </div>
          <div class="column col-3 col-sm-12 form-group">
            <label for="partial" class="form-label">Saldo a pagar</label>
            <input id="partial" readonly
                   :value="(form.courtesy ? 0 : totalOpened) | currency"
                   class="form-input text-right text-bold"
                   :class="totalOpened < 0 ? 'text-error' : ''"
                   :disabled="payment.cashier.status === 'closed'"
                   maxlength="7"/>
          </div>
          <div class="column col-4 col-sm-12 form-group"
               :class="{'has-error': $v.payment.methodId.$error}"
               v-if="!form.courtesy">
            <label class="form-label">Forma de pagamento</label>
            <select id="method" v-model="payment.methodId" class="form-select"
                    @blur="$v.payment.methodId.$touch()" @change="changePaymentMethod"
                    :disabled="payment.cashier.status === 'closed'">
              <option value="">[Selecione]</option>
              <option v-for="item in payment.methods" :value="item.id" :key="item.id">
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="column col-1 col-sm-12 form-group"
               v-if="enableInstallment && !form.courtesy">
            <label class="form-label">Qtde.</label>
            <select id="quantity" v-model="payment.installment"
                    class="form-select" @change="rateCalculate"
                    :disabled="payment.cashier.status === 'closed'">
              <option v-for="(item, i) in payment.installments"
                      :value="item.installment" :key="i">
                {{ item.installment }}
              </option>
            </select>
          </div>
          <div class="column col-2 col-sm-12 form-group"
               :class="{'has-error': $v.payment.dueDate.$error}"
               v-if="!form.courtesy">
            <label class="form-label">Vencimento</label>
            <dx-input-date
              id="due-date"
              class="form-input"
              v-model="payment.dueDate"
              @blur="$v.payment.dueDate.$touch()"
              :disabled="payment.cashier.status === 'closed'"
            />
          </div>
          <div class="column col-1 col-sm-12 form-group" v-if="!form.courtesy">
            <label class="form-label">Taxa (R$)</label>
            <dx-input-number id="installment-rate" class="form-input text-right" readonly
                             :disabled="payment.cashier.status === 'closed'"
                             v-model="payment.installmentRate" :precision="2" />
          </div>
          <div class="column col-2 col-sm-12 form-group"
               :class="{'has-error': $v.payment.value.$error}"
               v-if="!form.courtesy">
            <label class="form-label">Valor</label>
            <dx-input-number id="value" v-model="payment.value"
                             @focus="refreshInstallmentValue"
                             @change="recalculateValues"
                             @blur="$v.payment.value.$touch()"
                             class="form-input text-right"
                             :precision="2" maxlength="10"
                             :disabled="payment.cashier.status === 'closed'"/>
          </div>
          <div class="column col-auto col-sm-12 form-group mb-2"
               style="align-self: flex-end" v-if="!form.courtesy">
            <button class="btn btn-primary"
                    :disabled="totalOpened <= 0 || paid"
                    @click="addInstallment">Adicionar</button>
          </div>
          <div class="column col-auto col-sm-12 form-group mb-2"
               style="align-self: flex-end">
            <div class="status-toggle">
              <label class="form-switch">
                <input type="checkbox" v-model="form.courtesy"
                       :disabled="payment.cashier.status === 'closed' || paid" />
                <i class="form-icon"/>Cortesia
              </label>
            </div>
          </div>
        </div>
        <div v-if="service.particular && !form.courtesy && payment.enabled
        && payment.cashiers.length > 0" class="payment-body">
          <div class="empty" v-if="form.installments.length === 0">
            <div class="empty-icon">
              <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
            </div>
            <div class="empty-subtitle">
              Forma de pagamento não informada.
            </div>
          </div>
          <table class="table table-hover" v-else>
            <thead>
            <tr>
              <th width="30px">#</th>
              <th>Conta</th>
              <th>Forma de pagamento</th>
              <th class="text-center">Vencimento</th>
              <th class="text-right">Desconto</th>
              <th class="text-right">Juro</th>
              <th class="text-right">Taxa</th>
              <th class="text-right">Valor</th>
              <th width="60px"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(installment, i) in form.installments" :key="i">
              <td>{{ installment.method.installment.groupId }}</td>
              <td>{{ installment.bankAccount.name }}</td>
              <td>
                {{ installment.method.name }}
                <small v-if="installment.method.type === 'credit_card'">
                  ({{ installment.method.installment.current }})
                </small>
              </td>
              <td class="text-center">{{ formatDate(installment.dueDate) }}</td>
              <td class="text-right">{{ installment.discount | currency }}</td>
              <td class="text-right">{{ installment.interest | currency }}</td>
              <td class="text-right">{{ installment.rate | currency }}</td>
              <td class="text-right">{{ installment.value | currency }}</td>
              <td class="text-right">
                <button slot="action" @click="removeInstallment(installment)"
                        class="btn btn-gray btn-action btn-icon btn-sm"
                        :disabled="payment.cashier.status === 'closed' || paid">
                  <fa-icon :icon="['fal', 'times']"/>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <expense-items
          class="mt-2"
          :source="source"
          :expenses="form.expenses"
          :general-rules="planSettings.general"
          :insurance-plan-id="form.patient.insurance.plan.id"
        />
      </template>
    </div>
    <template slot="footer">
      <button class="btn btn-primary mr-1" :class="{loading: saving}"
              v-if="!finished && !closed"
              @click="save" :disabled="saving || paid">
        Finalizar
      </button>
      <button class="btn"
              @click="close(false)">Sair</button>
    </template>
  </dx-modal>
</template>

<script>
import formMixin from 'src/mixins/form';
import { round } from 'lodash';
import { mergeFrom } from '@/helpers/object';
import { mapActions, mapMutations, mapState } from 'vuex';
import { required, minValue, maxValue } from 'vuelidate/src/validators';
import { date } from '@/data/validators';
import { consultationTypes } from '@/data/consultation-types';
import { getName } from '@/data/cashier-statuses';
import { serviceTypes } from '@/data/service-types';
import moment from 'moment';
import { ALLOW_CASHIER_DISCOUNT_INTEREST } from '@/data/actions/modules/financial';
import cnpj from '../../../../data/validators/cnpj';
import cpf from '../../../../data/validators/cpf';
import ExpenseItems from '../../../billing/attendance/components/ExpenseItems.vue';

export default {
  mixins: [formMixin],
  components: {
    ExpenseItems,
  },
  props: {
    source: {
      type: String,
    },
    show: {
      type: Boolean,
    },
    type: {
      type: String,
    },
    data: {
      id: {
        type: String,
      },
      key: {
        type: String,
      },
      startDate: {
        type: String,
      },
      appointmentId: {
        type: String,
      },
      document: {
        type: String,
      },
      professionalId: {
        type: String,
      },
      patient: {
        id: {
          type: String,
        },
        name: {
          type: String,
        },
        birthDate: {
          type: Date,
        },
        gender: {
          type: Date,
        },
        insurance: {
          id: {
            type: String,
          },
          name: {
            type: String,
          },
          plan: {
            id: {
              type: String,
            },
            name: {
              type: String,
            },
          },
          record: {
            type: String,
          },
          validity: {
            type: Date,
          },
        },
      },
      installments: {
        type: Array,
      },
      expenses: {
        type: Array,
      },
      cashier: {
        id: {
          type: String,
        },
        bankAccount: {
          id: {
            type: String,
          },
          name: {
            type: String,
          },
        },
        category: {
          id: {
            type: String,
          },
          name: {
            type: String,
          },
        },
        dateTime: {
          type: Date,
        },
        openingDate: {
          type: Date,
        },
        closingDate: {
          type: Date,
        },
        modality: {
          type: String,
        },
        paymentSource: {
          name: {
            type: String,
          },
          type: {
            type: String,
          },
          value: {
            type: String,
          },
        },
        receipt: {
          date: {
            type: Date,
          },
          printed: {
            type: Boolean,
          },
        },
        status: {
          type: String,
        },
        user: {
          id: {
            type: String,
          },
          name: {
            type: String,
          },
        },
        value: {
          gross: {
            type: Number,
          },
          discount: {
            type: Number,
          },
          interest: {
            type: Number,
          },
          tax: {
            type: Number,
          },
          rate: {
            type: Number,
          },
          total: {
            type: Number,
          },
        },
      },
    },
  },
  data() {
    return {
      path: '/billing-accounts',
      getName,
      consultationTypes,
      serviceTypes,
      loading: false,
      saving: false,
      isCurrentInvoice: false,
      professionals: [],
      invoiceCodes: [],
      service: {
        insurance: false,
        particular: false,
      },
      payment: {
        enabled: true,
        source: {
          type: 'person',
          value: '',
          name: '',
        },
        cashiers: [],
        categories: [],
        bankAccounts: [],
        methods: [],
        installments: [],
        cashier: {
          id: '',
          modality: 'company',
          bankAccount: {
            id: '',
          },
          category: {
            id: '',
          },
          receipt: {
            date: null,
            printed: false,
          },
          status: 'open',
        },
        discount: {
          type: 'value',
          percent: 0,
          value: 0,
        },
        interest: {
          type: 'value',
          percent: 0,
          value: 0,
        },
        tax: 0,
        rate: 0,
        methodId: '',
        document: '',
        dueDate: '',
        installment: 1,
        installmentRate: 0,
        value: '',
      },
      planSettings: {
        providerReferral: {
          mode: 'manual',
        },
        general: {
          billing: {
            blockValues: false,
            hideInsuranceValue: false,
            billingForIndividualSessions: false,
          },
        },
      },
      form: this.blankForm(),
    };
  },
  validations() {
    const rules = {
      form: {
        referral: {
          type: { required },
          provider: { required },
        },
        serviceType: { required },
        consultationType: {},
        professionalId: { required },
      },
      payment: {
        source: {
          value: {},
        },
        discount: {
          value: {},
        },
        cashier: {
          id: {},
          category: {
            id: {},
          },
        },
        methodId: { required },
        dueDate: { required, date },
        value: {
          required,
          minValue: minValue(0.01),
          maxValue: maxValue(this.totalOpened),
        },
      },
    };

    if (this.form.serviceType === 'consultation') {
      rules.form.consultationType = { required };
    }

    if ((this.payment.discount.value || 0) > 0) {
      rules.payment.discount.value = {
        maxValue: maxValue(this.totalExpenses()),
      };
    }

    if (this.particularTotal > 0 && this.payment.enabled) {
      rules.payment.cashier.id = { required };
      rules.payment.cashier.category.id = { required };
    }

    if (this.particularTotal > 0 && this.payment.enabled && this.form.courtesy === false) {
      if (this.payment.source.type === 'company') {
        rules.payment.source.value = { cnpj };
      } else {
        rules.payment.source.value = { cpf };
      }
    }

    return rules;
  },
  created() {
    this.form = mergeFrom(this.blankForm(), this.data);
    if (this.data.cashier) {
      this.payment.cashier.id = this.data.cashier.id;
      this.payment.cashier.modality = this.data.cashier.modality;
      this.payment.cashier.bankAccount.id = this.data.cashier.bankAccount.id;
      this.payment.cashier.category.id = this.data.cashier.category.id;
      this.payment.source.name = this.data.cashier.paymentSource.name;
      this.payment.source.type = this.data.cashier.paymentSource.type;
      this.payment.source.value = this.data.cashier.paymentSource.value;
      if (this.data.cashier.receipt) {
        this.payment.cashier.receipt = this.data.cashier.receipt;
      }
      this.payment.cashier.status = 'open';
      if (this.data.cashier.status) {
        this.payment.cashier.status = this.data.cashier.status;
      }
    }

    if (this.source === 'appointments' && !this.form.id) {
      const [expense] = this.form.expenses;
      if (['consultation', 'exam'].includes(expense.type)) {
        this.form.serviceType = expense.type;
        if (expense.type === 'consultation') {
          this.form.consultationType = expense.return ? 'return' : 'first';
        }
      } else if (['session', 'evaluation'].includes(expense.type)) {
        this.form.serviceType = 'other_therapies';
      } else if (expense.type === 'laboratory') {
        this.form.serviceType = 'exam';
      } else if (expense.type === 'surgery') {
        this.form.serviceType = 'minor_surgery';
      } else {
        this.form.serviceType = 'small_service';
      }
    }

    if (this.form.consultationType === 'return') {
      this.form.expenses.forEach((expense) => {
        expense.value.insurance = 0;
        expense.value.particular = 0;
      });
    }

    if (!this.payment.source.value && this.source === 'appointments') {
      this.payment.source.type = 'person';
      this.payment.source.value = this.appointment.patient.identity.value;
      this.payment.source.name = this.appointment.patient.name;
    }

    if (this.insuranceTotal > 0) this.service.insurance = true;
    if (this.particularTotal > 0) this.service.particular = true;

    if (!this.form.referral.type) {
      if (this.form.serviceType === 'consultation') {
        this.form.referral.type = 'consultation';
      } else {
        this.form.referral.type = 'sadt';
      }
      if (this.particularTotal > 0 || this.form.patient.insurance.name === 'Particular') {
        this.form.referral.type = 'particular';
      }
    }
  },
  async mounted() {
    this.loading = true;
    await this.getCategories();
    await this.loadBankAccounts();
    await this.getOpenCashiers();
    this.loadProfessionals();
    this.loadInvoiceCodes();
    this.loadMethods();
    await this.loadPlanSettings();

    if (this.form.installments.length > 0) {
      this.payment.document = this.form.installments[0].document;
      this.payment.discount.value = this.form.installments.reduce((a, b) => a + b.discount, 0);
      this.payment.interest.value = this.form.installments.reduce((a, b) => a + b.interest, 0);
      this.payment.rate = this.form.installments.reduce((a, b) => a + b.rate, 0);
    }

    if (!this.payment.cashier.id && this.payment.cashiers.length === 1) {
      const cashier = this.payment.cashiers[0];
      this.payment.cashier.id = cashier.id;
      this.payment.cashier.bankAccount.id = cashier.bankAccount.id;
      this.payment.cashier.bankAccount.name = cashier.bankAccount.name;
      if (cashier.categories && cashier.categories.attendance
        // eslint-disable-next-line no-underscore-dangle
        && cashier.categories.attendance._id) {
        // eslint-disable-next-line no-underscore-dangle
        this.payment.cashier.category.id = cashier.categories.attendance._id;
      }
    }
    this.loading = false;
  },
  watch: {
    'form.courtesy': {
      handler() {
        this.form.installments = [];
        if (this.form.courtesy) {
          this.payment.discount.type = 'value';
          this.payment.discount.percent = 100;
          this.payment.discount.value = this.particularTotal;
        } else {
          this.payment.discount.type = 'value';
          this.payment.discount.value = 0;
          this.payment.discount.percent = 0;
        }
        this.payment.interest.type = 'value';
        this.payment.interest.value = 0;
        this.payment.interest.percent = 0;
        this.payment.rate = 0;
        this.payment.installmentRate = 0;
      },
    },
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      appointment: state => state.appointment.form,
    }),
    allowDiscountInterest() {
      return this.$can(ALLOW_CASHIER_DISCOUNT_INTEREST);
    },
    particularTotal() {
      return this.form.expenses.reduce((a, b) => a + Number(b.value.particular * b.quantity), 0);
    },
    insuranceTotal() {
      return this.form.expenses
        .reduce((a, b) => a + Number(b.value.insurance * b.quantity), 0);
    },
    rateTotal() {
      return round(this.form.installments.reduce((a, b) => a + b.rate, 0), 2);
    },
    totalOpened() {
      const installmentTotal = this.form.installments.reduce((a, b) => a + Number(b.value), 0);
      return round(round(this.particularTotal, 2)
        - round(installmentTotal, 2)
        - round(this.payment.discount.value, 2)
        + round(this.payment.interest.value, 2), 2);
    },
    enableInstallment() {
      if (!this.payment.methodId) {
        return false;
      }
      const found = this.payment.methods
        .find(({ id }) => id === this.payment.methodId);

      if (found && ['credit_card', 'check'].includes(found.documentType.type)) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.payment.installments = found.installments;
        return true;
      }

      return false;
    },
    finished() {
      return this.form.status === 'finished';
    },
    closed() {
      return this.payment.cashier.status === 'closed';
    },
    paid() {
      return this.form.expenses.some(({ financialBundleId }) => financialBundleId);
    },
  },
  methods: {
    ...mapMutations({
      setAccount: 'Appointment.SET_ACCOUNT',
    }),
    ...mapActions({
      apiFetchProfessionals: 'apiFetchProfessionals',
    }),
    close(save = false) {
      this.$emit('close', save ? this.form : null);
    },
    save() {
      this.$v.form.$touch();

      if (this.form.consultationType !== 'return' && this.service.particular) {
        if (this.payment.cashier.modality === 'company' && this.payment.cashiers.length === 0) {
          this.$toast.show(
            'Nenhum caixa aberto para dar sequência no recebimento do atendimento!',
            { type: 'error' },
          );
          return null;
        }

        this.$v.payment.cashier.id.$touch();
        if (this.$v.payment.cashier.id.$error) {
          return null;
        }
      }

      if (this.$v.form.$error) {
        return null;
      }

      if (this.form.expenses.length === 0) {
        this.$toast.error('Nenhum procedimento adicionado!');
        return null;
      }

      if (this.service.particular) {
        const foundCategory = this.payment.categories
          .find(item => item.id === this.payment.cashier.category.id);

        if (!foundCategory) {
          this.$toast.show('Selecione uma categoria!', { type: 'error' });
          this.payment.cashier.category.id = '';
          return null;
        }
      }

      if (this.payment.enabled && this.totalOpened && !this.form.courtesy) {
        this.$toast.show('Ainda existe saldo a pagar em aberto!', { type: 'error' });
        return null;
      }

      let status = 'open';
      if (this.particularTotal > 0 && this.payment.cashier.modality === 'company'
          && this.payment.enabled === false) {
        status = 'payment';
        this.form.installments = [];
      }
      this.saving = true;

      const branch = this.user.branch
      && this.user.branch.id
        ? { id: this.user.branch.id, name: this.user.branch.name }
        : null;

      const data = {
        id: this.form.id,
        branch,
        appointmentId: this.form.appointmentId,
        startDate: this.form.startDate,
        endDate: this.form.endDate,
        status,
        invoiceId: this.form.invoiceId,
        serviceType: this.form.serviceType,
        character: 'elective',
        referral: {
          type: this.form.referral.type,
          provider: this.form.referral.provider,
        },
        insurance: {
          id: this.form.patient.insurance.id,
          planId: this.form.patient.insurance.plan.id,
          insured: {
            record: this.form.patient.insurance.insured.record,
            name: this.form.patient.insurance.insured.name,
            validity: this.form.patient.insurance.insured.validity,
          },
        },
        patient: {
          id: this.form.patient.id,
          name: this.form.patient.name,
          record: this.form.patient.insurance.record,
          validity: this.form.patient.insurance.validity,
          insured: this.form.patient.name,
        },
        installments: this.form.installments
          .map((installment) => {
            installment.document = this.payment.document;
            return installment;
          }),
        courtesy: this.form.consultationType === 'return' ? true : this.form.courtesy,
        expenses: this.form.expenses.map(expense => ({
          id: expense.id,
          financialBundleId: expense.financialBundleId,
          startDate: this.form.startDate,
          endDate: this.form.endDate,
          code: expense.code,
          name: expense.name,
          accountId: expense.accountId,
          quantity: expense.quantity,
          professionalId: expense.professional.id,
          specialtyCode: expense.professional.specialty.code,
          accreditedProfessionalId: expense.professional.accredited
            ? expense.professional.accredited.id : null,
          accreditedSpecialtyCode: expense.professional.accredited
            && expense.professional.accredited.specialty
            ? expense.professional.accredited.specialty.code : null,
          degreeParticipationCode: expense.professional.degreeParticipation,
          way: expense.way ? expense.way : null,
          technique: expense.technique ? expense.technique : null,
          factor: expense.factor,
          value: {
            discount: expense.value.discount || 0,
            interest: expense.value.interest || 0,
            rate: expense.value.rate || 0,
            insurance: expense.value.insurance || 0,
            particular: expense.value.particular || 0,
          },
        })),
      };

      if (this.form.consultationType) {
        data.consultationType = this.form.consultationType;
      }

      if (this.payment.cashier.modality === 'company' && this.service.particular
          && this.form.consultationType !== 'return' && this.payment.enabled) {
        data.cashier = {
          _id: this.payment.cashier.id,
          modality: this.payment.cashier.modality,
          paymentSource: {
            name: this.payment.source.name,
            type: this.payment.source.type,
            value: this.payment.source.value || null,
          },
          bankAccount: {
            _id: this.payment.cashier.bankAccount.id,
            name: this.getBankAccountName(this.payment.cashier.bankAccount.id),
          },
          category: {
            _id: this.payment.cashier.category.id,
            name: this.getCategoryName(this.payment.cashier.category.id),
          },
          user: {
            _id: this.user.id,
            name: this.user.name,
          },
          receipt: {
            date: this.payment.cashier.receipt.date,
            printed: this.payment.cashier.receipt.printed,
          },
          value: {
            gross: this.particularTotal,
            discount: this.payment.discount.value,
            interest: this.payment.interest.value,
            tax: this.payment.tax,
            rate: this.payment.rate,
            total: this.particularTotal - this.payment.discount.value + this.payment.interest.value,
          },
        };
      }

      const request = !data.id
        ? this.$http.post(`${this.path}`, data)
        : this.$http.put(`${this.path}/${data.id}`, data);

      // eslint-disable-next-line consistent-return
      return request
        .then(({ data: result }) => {
          this.form.id = result.id;
          this.form.expenses.forEach((expense) => {
            const found = this.$store.state.appointment.form.expenses
              .find(({ id }) => id === expense.id);
            if (found) {
              found.accountId = result.id;
              delete found.selected;
            }
          });

          if (result.status !== 'payment'
            && this.$store.state.appointment.form.status === 'payment') {
            this.$store.state.appointment.form.status = 'waiting';
          }
          if (result.status === 'payment') {
            this.$store.state.appointment.form.status = 'payment';
          }

          this.setAccount({
            key: this.data.key,
            expenses: this.$store.state.appointment.form.expenses,
          });
          this.$toast.show('Salvo com sucesso');
          this.close(true);
        })
        .catch((e) => {
          if (e.response
            && e.response.data
            && e.response.data.message) {
            this.$toast.show(e.response.data.message, { type: 'error', timeout: 5000 });
          }
        })
        .then(() => {
          this.saving = false;
        });
    },
    loadInvoiceCodes() {
      const params = {
        insurancePlanId: this.data.patient.insurance.plan.id,
      };

      return this.$http.get('/billing-invoice-codes', { params })
        .then(({ data }) => {
          this.invoiceCodes = data.items;
          const found = this.invoiceCodes.find(({ status }) => status === 'open');
          this.isCurrentInvoice = !!found;

          if (!this.form.invoiceId && this.isCurrentInvoice) {
            if (found) {
              this.form.invoiceId = found.id;
            } else {
              this.form.invoiceId = this.invoiceCodes[0].id;
            }
          }
        })
        .catch(() => {});
    },
    loadProfessionals() {
      return this.apiFetchProfessionals()
        .then((items) => {
          items.forEach((item) => {
            item.professional.specialties.forEach((specialty) => {
              this.professionals.push({
                id: item.id,
                name: item.name,
                specialty,
              });
            });
          });
        })
        .catch(() => {});
    },
    loadBankAccounts() {
      const params = {
        active: true,
        limit: 0,
      };
      return this.$http.get('/bank-accounts', { params })
        .then(({ data }) => {
          this.payment.bankAccounts = data.items;
        })
        .catch(() => {});
    },
    loadMethods() {
      const params = {
        active: true,
        limit: 0,
      };
      this.$http.get('/payment-methods', { params })
        .then(({ data }) => {
          this.payment.methods = data.items;
        })
        .catch(() => {});
    },
    addInstallment() {
      this.$v.payment.$touch();
      if (this.$v.payment.$error) {
        return;
      }
      const groupId = this.getGroupId();

      const method = this.payment.methods
        .find(({ id }) => id === this.payment.methodId);

      const bankAccountId = method.documentType.type === 'money'
        ? this.payment.cashier.bankAccount.id
        : method.bankAccount.id;

      const bankAccount = this.payment.bankAccounts.find(({ id }) => id === bankAccountId);

      const total = round(this.payment.value / this.payment.installment, 2);

      let dueDate = moment(this.payment.dueDate).format('YYYY-MM-DD');
      let totalInstallments = 0;
      for (let i = 1; i <= this.payment.installment; i += 1) {
        totalInstallments += total;

        if (method && method.compensation && ['credit_card', 'check'].includes(method.documentType.type)) {
          if (method.compensation.type === 'after-day' && i > 1) {
            if (method.compensation.value === 30) {
              dueDate = moment(dueDate).add(1, 'month').format('YYYY-MM-DD');
            } else {
              dueDate = moment(dueDate)
                .add(method.compensation.value, 'd')
                .format('YYYY-MM-DD');
            }
          }
        }

        this.form.installments.push({
          dueDate: moment(dueDate).format('YYYY-MM-DD'),
          bankAccount: {
            id: bankAccount.id,
            name: bankAccount.name,
          },
          method: {
            id: method.id,
            name: method.name,
            type: method.documentType.type,
            dueDate: moment(dueDate).format('YYYY-MM-DD'),
            compensation: method.compensation,
            installments: method.installments,
            installment: {
              total: this.payment.installment,
              current: i,
              groupId,
            },
          },
          discount: 0,
          tax: 0,
          rate: 0,
          interest: 0,
          value: total,
        });
      }

      let difference = round(
        round(totalInstallments, 2) - round(this.payment.value, 2),
        2,
      );

      if (difference !== 0) {
        const installments = this.form.installments.filter(item => item.method.id === method.id);
        installments.forEach((item) => {
          if (difference !== 0) {
            item.value = round(item.value + (difference > 0 ? -0.01 : 0.01), 2);
            difference += round(difference > 0 ? -0.01 : 0.01, 2);
          }
        });
      }

      this.calculateDiscountApportionment();
      this.calculateInterestApportionment();
      this.calculateRateApportionment(groupId);
      this.clearPayment();
    },
    getGroupId() {
      const { installments } = this.form;

      let id = 0;
      if (installments && installments.length > 0) {
        installments.forEach((installment) => {
          if (installment.method.installment.groupId > id) {
            id = installment.method.installment.groupId;
          }
        });
      }
      return id + 1;
    },
    removeInstallment(item) {
      const installments = this.form.installments.filter(
        installment => installment.method.installment.groupId === item.method.installment.groupId,
      );

      if (installments.length > 1) {
        this.$toast.show(`As parcelas do '${installments[0].method.name}' foram removidas
          com sucesso!`);
      } else {
        this.$toast.show('Parcela removida com sucesso!');
      }

      this.form.installments = this.form.installments.filter(
        installment => installment.method.installment.groupId !== item.method.installment.groupId,
      );
      this.payment.rate = this.rateTotal;
    },
    clearPayment() {
      this.$v.payment.$reset();
      this.payment.methodId = '';
      this.payment.installment = 1;
      this.payment.installmentRate = 0;
      this.payment.value = '';
    },
    clearConsultation() {
      if (this.form.serviceType !== 'consultation') {
        this.form.consultationType = null;
      }
    },
    async loadPlanSettings() {
      const insuranceId = this.form.patient.insurance.id;
      const planId = this.form.patient.insurance.plan.id;
      const params = `/insurances/${insuranceId}/plans/${planId}`;

      if (this.form.patient.insurance.plan.id) {
        await this.$http.get(params)
          .then(({ data }) => {
            if (data.settings.general.providerReferral) {
              this.planSettings.providerReferral.mode = data.settings.general.providerReferral.mode;
            }
            if (data.settings.general.billing) {
              if (data.settings.general.billing.blockValues) {
                this.planSettings.general.billing.blockValues = data.settings
                  .general.billing.blockValues;
              }
              if (data.settings.general.billing.hideInsuranceValue) {
                this.planSettings.general.billing.hideInsuranceValue = data.settings
                  .general.billing.hideInsuranceValue;
              }
            }
          })
          .catch(() => {
          });
      }
      if (this.planSettings.providerReferral.mode === 'automatic') {
        if (!this.form.referral.provider) {
          this.form.referral.provider = 'GUIA_AUTOMATICA';
        }
      }
    },
    loadEntity() {
      if (!this.payment.source.value) return;
      if (this.$v.payment.source.value.$error) return;

      const identity = this.onlyNumbers(this.payment.source.value);
      this.payment.source.name = '';

      this.$http
        .get(`/entities/${identity}`)
        .then(({ data }) => {
          if (identity.length === 14) {
            this.payment.source.type = 'company';
            this.payment.source.name = data.companyName;
          } else {
            this.payment.source.type = 'person';
            this.payment.source.name = data.name;
          }
        })
        .catch(() => {});
    },
    onlyNumbers(value, defaultValue = null) {
      let localValue = value;

      switch (typeof localValue) {
        case 'string':
          localValue = localValue.replace(/\D/g, '');
          return localValue === '' ? defaultValue : localValue;
        case 'number':
          return localValue.toString();
        default:
          return defaultValue;
      }
    },
    totalExpenses() {
      return this.form.expenses.reduce((a, b) => a + b.value.particular * b.quantity, 0);
    },
    calculateDiscountApportionment() {
      this.form.expenses.forEach((expense) => { expense.value.discount = 0; });
      this.form.installments.forEach((installment) => { installment.discount = 0; });
      this.procedureDiscountApportionment();
      this.installmentDiscountApportionment();
    },
    calculateInterestApportionment() {
      this.form.expenses.forEach((expense) => { expense.value.interest = 0; });
      this.form.installments.forEach((installment) => { installment.interest = 0; });
      this.procedureInterestApportionment();
      this.installmentInterestApportionment();
    },
    calculateRateApportionment(groupId) {
      this.form.expenses.forEach((expense) => {
        expense.value.rate = 0;
      });

      this.installmentRateApportionment(this.payment.installmentRate || 0, groupId);

      this.payment.rate = this.rateTotal;
      this.procedureRateApportionment();
    },
    procedureInterestApportionment() {
      let total = 0;
      const totalInterest = round(this.payment.interest.value, 2);
      const totalExpenses = round(this.totalExpenses(), 2);
      if (totalExpenses - totalInterest === 0) return;
      const percent = totalInterest / totalExpenses;
      this.form.expenses.forEach((expense) => {
        expense.value.interest = round(expense.value.particular * expense.quantity * percent, 2);
        total += expense.value.interest;
      });

      const installmentTotal = this.form.expenses.reduce((a, b) => a + b.value.interest, 0);
      if (installmentTotal === totalExpenses - totalInterest) {
        this.form.expenses[0].value.interest += totalInterest - total;
      }
    },
    procedureRateApportionment() {
      let total = 0;
      const totalRate = round(this.payment.rate, 2);
      const totalExpenses = round(this.totalExpenses() - (this.payment.discount.value || 0)
        + (this.payment.interest.value || 0), 2);

      if (totalExpenses - totalRate === 0) return;
      const percent = totalRate / totalExpenses;
      this.form.expenses.forEach((expense) => {
        expense.value.rate = round((expense.value.particular * expense.quantity
          - expense.value.discount + expense.value.interest) * percent, 2);
        total += expense.value.rate;
      });

      const installmentTotal = this.form.expenses.reduce((a, b) => a + b.value.rate, 0);
      if (installmentTotal === totalExpenses - totalRate) {
        this.form.expenses[0].value.rate += totalRate - total;
      }
    },
    procedureDiscountApportionment() {
      let total = 0;
      const totalDiscount = round(this.payment.discount.value, 2);
      const totalExpenses = round(this.totalExpenses(), 2);
      if (totalExpenses - totalDiscount === 0) return;
      const percent = totalDiscount / totalExpenses;
      this.form.expenses.forEach((expense) => {
        expense.value.discount = round(expense.value.particular * expense.quantity * percent, 2);
        total += expense.value.discount;
      });

      const installmentTotal = this.form.expenses.reduce((a, b) => a + b.value.discount, 0);
      if (installmentTotal === totalExpenses - totalDiscount) {
        this.form.expenses[0].value.discount += totalDiscount - total;
      }
    },
    installmentInterestApportionment() {
      let total = 0;
      const totalInterest = round(this.payment.interest.value, 2);
      const totalExpenses = round(this.totalExpenses(), 2);

      this.form.installments.forEach((installment) => { installment.interest = 0; });
      if (totalExpenses - totalInterest === 0) return;
      this.form.installments.forEach((installment) => {
        const percent = installment.value / (totalExpenses + totalInterest);
        installment.interest = round(percent * totalInterest, 2);
        total += installment.interest;
      });

      const installmentTotal = this.form.installments.reduce((a, b) => a + b.value, 0);
      if (installmentTotal === totalExpenses + totalInterest) {
        this.form.installments[0].interest += totalInterest - total;
      }
    },
    installmentRateApportionment(value, groupId) {
      const installments = this.form.installments
        .filter(item => item.method.installment.groupId === groupId);

      let ratePercent = 0;
      if (
        installments.length > 0
        && installments[0].method.installments
        && installments[0].method.installments.length > 0
      ) {
        ratePercent = installments[0].method.installments[installments.length - 1].value;
      }

      let total = 0;
      installments.forEach((installment) => {
        total += installment.value;
        installment.rate = Math.round(installment.value * ratePercent) / 100;
      });

      if (installments.length > 0) {
        installments[0].rate += Math.round(total * ratePercent) / 100
          - installments.reduce((acc, { rate }) => acc + rate, 0);
      }
    },
    installmentDiscountApportionment() {
      let total = 0;
      const totalDiscount = round(this.payment.discount.value, 2);
      const totalExpenses = round(this.totalExpenses(), 2);

      if (totalExpenses - totalDiscount === 0) return;
      this.form.installments.forEach((installment) => {
        const percent = installment.value / (totalExpenses - totalDiscount);
        installment.discount = round(percent * totalDiscount, 2);
        total += installment.discount;
      });

      const installmentTotal = this.form.installments.reduce((a, b) => a + b.value, 0);
      if (installmentTotal === totalExpenses - totalDiscount) {
        this.form.installments[0].discount += totalDiscount - total;
      }
    },
    refreshInstallmentValue() {
      if (!this.payment.value) {
        this.payment.value = this.totalOpened;
        this.recalculateValues();
      }
      this.$v.payment.value.$touch();
    },
    dueDateCalculate() {
      if (!this.payment.methodId) return;
      let dueDate = moment().format('YYYY-MM-DD');

      const method = this.payment.methods.filter(item => item.id === this.payment.methodId).pop();
      const currentDate = moment().format('YYYY-MM-DD');
      if (method && method.compensation) {
        if (method.compensation.type === 'after-day') {
          dueDate = moment(currentDate)
            .add(method.compensation.value, 'days')
            .format('YYYY-MM-DD');
        } else if (method.compensation.type === 'fixed-day') {
          dueDate = moment(currentDate)
            .set('date', method.compensation.value)
            .format('YYYY-MM-DD');
          if (moment(dueDate).diff(currentDate, 'days') < 0) {
            dueDate = moment(dueDate)
              .add(1, 'months')
              .format('YYYY-MM-DD');
          }
        } else if (method.compensation.type === 'same-day') {
          dueDate = currentDate;
        }
      } else {
        dueDate = currentDate;
      }
      this.payment.dueDate = dueDate;
    },
    changePaymentMethod() {
      this.rateCalculate();
      this.dueDateCalculate();
    },
    rateCalculate() {
      if (!this.payment.methodId) return;

      let ratePercent = 0;
      const method = this.payment.methods.filter(item => item.id === this.payment.methodId).pop();
      if (method.installments && method.installments.length > 0) {
        const installment = method.installments
          .filter(item => item.installment === this.payment.installment);
        if (installment && installment.length > 0) {
          ratePercent = installment[0].value || 0;
        }
      }
      this.payment.installmentRate = round(ratePercent * this.payment.value / 100, 2);

      this.dueDateCalculate();
    },
    formatDate(paymentDate) {
      return moment(paymentDate).format('DD/MM/YYYY');
    },
    getCategoryName(id) {
      if (!id) return '';
      const category = this.payment.categories.find(item => item.id === id);
      return category.name;
    },
    getBankAccountName(id) {
      if (!id) return '';
      const cashier = this.payment.cashiers.find(item => item.bankAccount.id === id);
      return cashier.bankAccount.name;
    },
    calculateDiscountPercentValue() {
      let discount = 0;
      if (this.payment.discount.type === 'percent') {
        if (this.payment.discount.percent && this.particularTotal > 0) {
          discount = Math.round(this.payment.discount.percent * this.particularTotal) / 100;
        }
        this.payment.discount.value = discount;
      } else {
        if (this.payment.discount.value && this.particularTotal > 0) {
          discount = Math.round(this.payment.discount.value / this.particularTotal * 10000) / 100;
        }
        this.payment.discount.percent = discount;
      }
    },
    calculateInterestPercentValue() {
      let interest = 0;
      if (this.payment.interest.type === 'percent') {
        if (this.payment.interest.percent && this.particularTotal > 0) {
          interest = Math.round(this.payment.interest.percent * this.particularTotal) / 100;
        }
        this.payment.interest.value = interest;
      } else {
        if (this.payment.interest.value && this.payment.total) {
          interest = Math.round(this.payment.interest.value / this.particularTotal * 10000) / 100;
        }
        this.payment.interest.percent = interest;
      }
    },
    recalculateValues() {
      this.rateCalculate();
      this.calculateDiscountPercentValue();
      this.calculateInterestPercentValue();
    },
    changeModality() {
      this.payment.enabled = (this.payment.cashier.modality === 'company');
    },
    async getOpenCashiers() {
      let cashiers = [];
      await this.$http.get('/cashiers/accounts')
        .then(({ data }) => {
          cashiers = data.items.map(cashier => ({
            // eslint-disable-next-line no-underscore-dangle
            id: cashier.bankAccount._id,
            name: cashier.bankAccount.name,
            categories: cashier.categories,
          }));
        })
        .catch(() => {});
      const params = {
        limit: 0,
      };
      await this.$http.get('/cashiers?status=open', { params })
        .then(({ data }) => {
          data.items.forEach((openCashier) => {
            const cashier = cashiers
              .find(item => openCashier.bankAccount.id === item.id);
            if (cashier) {
              openCashier.categories = cashier.categories;
              openCashier.enabled = true;
            } else {
              openCashier.enabled = false;
            }
          });
          this.payment.cashiers = data.items
            .filter(openCashier => openCashier.enabled === true)
            .map(item => ({
              id: item.id,
              bankAccount: {
                id: item.bankAccount.id,
                name: item.bankAccount.name,
              },
              categories: item.categories,
            }))
            .sort((a, b) => {
              if (a.bankAccount.name < b.bankAccount.name) return -1;
              if (a.bankAccount.name > b.bankAccount.name) return 1;
              return 0;
            });
          if (!this.payment.cashier.id && this.payment.cashiers.length === 0) {
            this.payment.enabled = false;
          }
        })
        .catch(() => {});

      if (this.payment.cashier.id) {
        const bankAccount = this.payment.bankAccounts
          .find(item => item.id === this.payment.cashier.bankAccount.id);
        this.payment.cashiers = [
          {
            id: this.payment.cashier.id,
            bankAccount: {
              id: bankAccount ? bankAccount.id : null,
              name: bankAccount ? bankAccount.name : 'Não informado',
            },
            categories: {},
          },
        ];
      }
    },
    async getCategories() {
      const params = {
        active: true,
        limit: 0,
      };

      await this.$http.get('/categories', { params })
        .then(({ data }) => {
          if (data.items) {
            this.payment.categories = data.items.filter(item => item.type === 'revenue');
          }
        })
        .catch(() => {});
    },
    changeCashier() {
      if (this.payment.cashier.id) {
        const cashier = this.payment.cashiers.find(item => item.id === this.payment.cashier.id);
        this.payment.cashier.bankAccount.id = cashier.bankAccount.id;
        if (cashier.categories && cashier.categories.attendance
          // eslint-disable-next-line no-underscore-dangle
          && cashier.categories.attendance._id) {
          // eslint-disable-next-line no-underscore-dangle
          this.payment.cashier.category.id = cashier.categories.attendance._id;
        }
      } else {
        this.payment.cashier.bankAccount.id = '';
      }
    },
    blankForm() {
      return {
        id: '',
        key: '',
        status: '',
        referral: {
          type: '',
          provider: '',
          insurance: '',
        },
        startDate: '',
        endDate: '',
        serviceType: '',
        consultationType: '',
        appointmentId: '',
        professionalId: '',
        specialtyCode: '',
        invoiceId: '',
        invoice: {
          id: '',
          code: '',
        },
        paymentDate: '',
        methodId: '',
        patient: {
          id: '',
          name: '',
          birthDate: '',
          gender: '',
          insurance: {
            id: '',
            name: '',
            plan: {
              id: '',
              name: '',
            },
            insured: {
              name: '',
              record: '',
              validity: '',
            },
          },
        },
        installments: [],
        courtesy: false,
        expenses: [],
      };
    },
  },
};
</script>

<style lang="scss">
@import "../../../../assets/scss/variables";
.particular-account {
  .cashier-message {
    background-color: lighten($info-color, 35%);
    border: $info-color solid $border-width;
    border-radius: $border-radius;
    font-size: $font-size-xs;
    padding: $layout-spacing-sm;
  }
  .payment-body {
    background-color: $gray-color-ultra-light;
    border: $border-color solid $border-width;
    border-radius: $border-radius;
    padding: $layout-spacing;
    .empty {
      padding: $layout-spacing-sm;
      .empty-icon {
        margin-bottom: $layout-spacing-sm;
      }
      .empty-subtitle {
        margin: 0;
      }
    }
  }
}
</style>
