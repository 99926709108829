import buildUrl from 'axios/lib/helpers/buildURL';
import printJS from 'print-js';
import { apiUrlX as apiUrl } from 'src/config';
import { show as showToast } from '@/plugins/toast';

// eslint-disable-next-line no-underscore-dangle
let _Vue;

function link(route, params = {}, options = {}) {
  options = {
    method: 'GET',
    ...options,
  };

  if (!route) {
    return Promise.reject(new Error('Invalid url'));
  }

  return _Vue.http.get('/download-token')
    .then(({ data }) => (
      buildUrl(apiUrl + route, (
        options.method.toUpperCase() === 'POST' ? data : { ...params, ...data }
      ))
    ));
}

async function download(route, params = {}, options = {}) {
  options = {
    method: 'GET',
    name: null,
    ...options,
  };

  const isBlob = options.method.toUpperCase() === 'POST';

  const url = await link(route, params, options);

  const linkEl = document.createElement('a');
  linkEl.type = 'hidden';
  linkEl.href = url;

  if (isBlob) {
    const { data } = await _Vue.http.post(url, params, {
      responseType: 'blob',
    });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(data);
      return;
    }

    linkEl.href = URL.createObjectURL(data);

    if (options.name) {
      linkEl.download = options.name;
    }
  }

  // Append link to the body
  document.body.appendChild(linkEl);

  // this is necessary as linkEl.click() does not work on the latest firefox
  linkEl.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );

  setTimeout(() => {
    if (isBlob) {
      // For Firefox it is necessary to delay revoking the ObjectURL
      URL.revokeObjectURL(linkEl.href);
    }
    document.body.removeChild(linkEl);
  }, 100);
}

function print(route, params = {}, options = {}) {
  options = {
    method: 'GET',
    ...options,
  };

  if (options.method.toUpperCase() === 'POST') {
    return link(route, params, options)
      .then(url => _Vue.http.post(url, params, { responseType: 'arraybuffer' }))
      .then(({ data }) => new Promise((resolve) => {
        printJS({
          printable: Buffer.from(data, 'binary').toString('base64'),
          type: 'pdf',
          onLoadingStart: () => {},
          onLoadingEnd: resolve,
          base64: true,
        });
      }));
  }

  return link(route, params, options)
    .then(url => new Promise((resolve) => {
      printJS({
        printable: url,
        type: 'pdf',
        onLoadingStart: () => {},
        onLoadingEnd: resolve,
        onError: (e) => {
          const message = typeof e === 'string' && e.toUpperCase() === 'FORBIDDEN'
            ? 'Acesso negado'
            : e.toString();
          showToast(message, { type: 'error', timeout: 7000 });
        },
      });
    }));
}

export default function install(Vue) {
  _Vue = Vue;

  Object.defineProperty(Vue.prototype, '$file', {
    get() {
      return { link, download, print };
    },
  });
}
