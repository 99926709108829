import API from '@/helpers/api';

export async function index(params = {}) {
  return API('/professionals', params).fetch();
}

export async function allActive(allBranches = false) {
  const params = {
    limit: 0,
    active: true,
  };
  if (allBranches) {
    params.notFilterBranch = true;
  }
  return index(params);
}

export async function get(id) {
  return API(`/professionals/${id}`).fetch();
}
