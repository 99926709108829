<template>
  <div id="app" class="master-layout" :class="conditionalClasses">
    <div class="notification-bar" v-if="expiresNotification">
      {{ expiresNotification }}
    </div>
    <header class="navbar main-nav">
      <section class="navbar-section">
        <div class="columns">
          <div class="column col-auto">
            <router-link to="/" tag="section" class="c-hand navbar-section">
              <img v-if="customLogo" :src="customLogo" alt="Stenci" class="logo">
              <img v-else src="../assets/svg/logo-stenci.svg" alt="Stenci" class="logo">
            </router-link>
          </div>
          <div class="divider-vert"></div>
          <div class="column">
            <div class="page-name">
              <portal-target name="page-name"></portal-target>
            </div>
            <div class="module-name" @click="modules.show=true">
              Módulo: <template v-if="currentModule">{{ currentModule.name }}</template>
            </div>
          </div>
        </div>
      </section>
      <section class="navbar-section">
        <button class="btn btn-icon btn-link btn-menu-action text-warning"
                @click="$router.push('/tutorial')">
          <fa-icon :icon="['fal', 'question-circle']"/>
        </button>
        <button class="btn btn-icon btn-link btn-menu-action"
                @click="expenseModalShow = true">
          <fa-icon :icon="['fal', 'search']"/>
        </button>
        <button
          @click="toggleChat"
          class="btn btn-icon btn-link btn-chat"
          :class="{ badge: chatUnread }">
          <fa-icon :icon="['fal', 'comment']"/>
        </button>
        <div class="divider-vert"></div>
        <div class="tile tile-centered user-menu" @click="toggleUserMenu">
          <div class="tile-icon">
            <figure class="avatar">
              <img :src="userImage" :alt="user.name">
            </figure>
          </div>
          <div class="tile-content">
            <div class="user-name">{{ name }}</div>
            <div class="user-branch">{{ user.branch ? user.branch.name : '-' }}</div>
          </div>
          <div class="tile-action">
            <fa-icon :icon="['fas', 'caret-down']"/>
          </div>
        </div>
        <ul class="menu user-menu-list" v-if="userMenu.show" @click.stop>
          <li class="menu-item">
            <router-link to="/me">
              <fa-icon :icon="['fal', 'user-circle']"/>
              <span class="side-menu-label ml-2">Meu Perfil</span>
            </router-link>
          </li>
          <li class="menu-item">
            <a class="c-hand" @click.prevent="modules.show = true">
              <fa-icon :icon="['fal', 'send-backward']"/>
              <span class="side-menu-label ml-2">Módulos</span>
            </a>
          </li>
          <li class="menu-item">
            <router-link to="/" @click.native.prevent="branch.show = true">
              <fa-icon :icon="['fal', 'building']"/>
              <span class="side-menu-label ml-2">Trocar de Unidade</span>
            </router-link>
          </li>
          <li class="divider"/>
          <li class="menu-item">
            <router-link to="/customer" v-if="canManageCustomerAccount" @click.stop>
              <fa-icon :icon="['fal', 'shield-check']"/>
              <span class="side-menu-label ml-2">Conta do cliente</span>
            </router-link>
          </li>
          <li class="menu-item">
            <router-link to="/settings" v-if="userMenu.show" @click.stop>
              <fa-icon :icon="['fal', 'cog']"/>
              <span class="side-menu-label ml-2">Configurações gerais</span>
            </router-link>
          </li>
          <li class="divider"/>
          <li class="menu-item">
            <router-link to="/tickets">
              <fa-icon :icon="['fal', 'life-ring']"/>
              <span class="side-menu-label ml-2">Ajuda e sugestões</span>
            </router-link>
          </li>
          <li class="divider"/>
          <li class="menu-item">
            <router-link to="/logout">
              <fa-icon :icon="['fal', 'sign-out-alt']"/>
              <span class="side-menu-label ml-2">Sair</span>
            </router-link>
          </li>
        </ul>
      </section>
    </header>
    <section class="primary">
      <aside class="side-menu">
        <component :is="currentModuleMenu"></component>
      </aside>
      <main class="main">
        <expired-state v-if="isBlocked" />
        <router-view v-else-if="canAccess" />
        <forbidden-state v-else>
          <button
            class="btn btn-primary btn-icon btn-icon-left"
            @click="$router.back()"
          >
            <fa-icon :icon="['fal', 'chevron-left']"></fa-icon>
            <span>Voltar</span>
          </button>
        </forbidden-state>
      </main>
    </section>

    <dx-modal
      v-model="branch.show"
      title="Selecione uma clínica"
      id="branch-modal"
      :closable="isSetBranch">
      <template v-if="user.branches.length === 0">
        <div class="empty">
          <div class="empty-icon">
            <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
          </div>
          <p class="empty-title h5">Lista de clínicas</p>
          <p class="empty-subtitle">Nenhuma clínica vinculada ao seu usuário.</p>
        </div>
      </template>
      <template v-else>
        <div class="form-group" v-if="user.branches.length > 4">
          <div class="input-group pb-2">
            <input
              type="text"
              class="form-input input-lg"
              v-model="branch.search"
              placeholder="Pesquisar..."
              v-focus
            >
            <button
              class="btn btn-lg btn-icon btn-action btn-gray-outline input-group-btn"
            ><fa-icon :icon="['fal', 'search']"></fa-icon></button>
          </div>
        </div>
        <div class="branches">
          <div
            v-for="branch in availableBranches"
            :key="branch.id"
            @click="switchBranch(branch.id)"
            class="tile c-hand disabled"
            :class="{ active: user.branch && user.branch.id === branch.id }">
            <div class="tile-icon">
              <figure class="avatar" :data-initial="$avatarLetters(branch.name)"></figure>
            </div>
            <div class="tile-content">
              <div class="tile-title">{{ branch.name }}</div>
              <div class="tile-subtitle">{{ branch.address | address }}</div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <router-link class="btn btn-neutral btn-icon btn-icon-left" to="/logout">
          <fa-icon :icon="['fal', 'sign-out-alt']"/>Sair
        </router-link>
      </template>
    </dx-modal>

    <module-chooser-modal
      :show="modules.show"
      @close="modules.show = false"
    ></module-chooser-modal>

    <expense-modal-pricing
      v-if="expenseModalShow"
      :show="expenseModalShow"
      @close="expenseModalShow = false"
    />
    <chat v-if="isSetBranch" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { init as initMemed } from '@/services/memed';
import { appMode, customLogo } from '@/config';
import { MANAGE_CUSTOMER_ACCOUNT } from '@/data/actions/modules/general';
import Chat from '@/components/chat/Chat.vue';
import ForbiddenState from '@/components/auth/Forbidden.vue';
import ExpiredState from '@/components/auth/Expired.vue';
import ClinicalMenu from './master/ClinicalMenu.vue';
import OccupationalMenu from './master/OccupationalMenu.vue';
import PromptServiceMenu from './master/PromptServiceMenu.vue';
import ModuleChooserModal from './master/ModuleChooserModal.vue';
import FinancialMenu from './master/FinancialMenu.vue';
import HospitalizationMenu from './master/HospitalizationMenu.vue';
import ReferralMenu from './master/ReferralMenu.vue';
import StockMenu from './master/StockMenu.vue';
import SurgeryMenu from './master/SurgeryMenu.vue';
import ExpenseModalPricing from '../app/procedure/components/PricingModal.vue';

export default {
  data() {
    return {
      expenseModalShow: false,
      userMenu: {
        show: false,
      },
      branch: {
        show: false,
        changing: false,
        search: '',
      },
      modules: {
        show: false,
      },
      customLogo: customLogo(),
    };
  },
  components: {
    Chat,
    ExpenseModalPricing,
    ForbiddenState,
    ExpiredState,
    ModuleChooserModal,
    ClinicalMenu,
    OccupationalMenu,
    PromptServiceMenu,
    FinancialMenu,
    HospitalizationMenu,
    ReferralMenu,
    StockMenu,
    SurgeryMenu,
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
    ...mapGetters({
      chatUnread: 'chatUnread',
      currentModule: 'currentModule',
      branchIsExpired: 'branchIsExpired',
      expiresNotification: 'expiresNotification',
      isProfessional: 'isProfessional',
    }),
    conditionalClasses() {
      return {
        'chat-active': this.$store.state.chat.show,
        'has-notification': this.expiresNotification,
      };
    },
    availableBranches() {
      const search = this.branch.search.trim();
      if (search) {
        return this.user.branches.filter(({ name }) => (
          new RegExp(search, 'i').test(name)
        ));
      }
      return this.user.branches;
    },
    isSetBranch() {
      return this.user && this.user.branch !== null;
    },
    name() {
      return this.user
        ? this.user.name.split(' ').slice(0, 3).join(' ')
        : '';
    },
    isBlocked() {
      return this.branchIsExpired && !this.$route.path.startsWith('/customer');
    },
    canAccess() {
      if (this.isBlocked) {
        return false;
      }
      if ('meta' in this.$route && 'permission' in this.$route.meta) {
        return this.$can(this.$route.meta.permission);
      }
      return true;
    },
    userImage() {
      return this.user.imageUrl
        ? `${this.user.imageUrl}?w=200&h=200`
        : `//static.stenci.pro/images/no-image-${this.user.gender}.png`;
    },
    currentModuleMenu() {
      return `${this.currentModule.key}-menu`;
    },
    canManageCustomerAccount() {
      return this.$can(MANAGE_CUSTOMER_ACCOUNT);
    },
  },
  methods: {
    ...mapMutations({
      toggleChat: 'Chat.TOGGLE',
    }),
    init() {
      if (this.isSetBranch) {
        this.initMemed();
      } else {
        this.branch.show = true;
      }
    },
    initMemed() {
      if (this.isProfessional && appMode && appMode !== 'development') {
        setTimeout(() => {
          initMemed().catch(() => {});
        }, 1000);
      }
    },
    switchBranch(id) {
      if (this.branch.changing) return;

      this.branch.changing = true;
      this.$http.post('/me/branch', {
        branchId: id,
      })
        .then(({ data }) => {
          this.$store.dispatch('setUser', data.user);
          this.$store.dispatch('setToken', data.token);
          this.$store.dispatch('setModule', 'clinical');
        })
        .catch(() => {})
        .then(() => {
          setTimeout(() => {
            this.branch.changing = false;
            this.branch.show = false;
            this.$nextTick(() => {
              document.location.reload();
            });
          }, 300);
        });
    },
    toggleUserMenu() {
      if (this.userMenu.show) {
        this.closeUserMenu();
      } else {
        this.showUserMenu();
      }
    },
    showUserMenu() {
      this.userMenu.show = true;
      setTimeout(() => document
        .addEventListener('click', this.closeUserMenu), 1);
    },
    closeUserMenu() {
      this.userMenu.show = false;
      document.removeEventListener('click', this.closeUserMenu);
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
@import "./src/assets/scss/_colors.scss";

@keyframes blink-animation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

$header-height: 3.6rem;
$notification-bar-height: 1.6rem;
$sidebar-width: 5.7rem;
.master-layout {
  background-color: #F6F9FA;
  display: grid;
  grid-template-rows: $header-height auto;
  height: 100vh;

  &.has-notification {
    grid-template-rows: $notification-bar-height $header-height auto;
  }

  .page-name {
    font-size: $font-size;
    font-weight: 500;
  }
  .module-name {
    color: $info-color;
    cursor: pointer;
    font-size: $font-size-xs;
    &:hover {
      text-decoration: underline;
    }
  }
  .user-menu {
    border: $border-width solid $border-color;
    border-radius: $border-radius;
    cursor: pointer;
    padding: $layout-spacing-sm $layout-spacing;
    .tile-content {
      line-height: normal;
    }
    .tile-action {
      padding-left: $layout-spacing-sm;
    }
    .user-name {
      font-weight: 500;
      margin-bottom: $border-width;
    }
    .user-branch {
      color: $gray-color-dark;
      font-size: $font-size-xs;
    }
  }
  .user-menu-list {
    position: absolute;
    top: $header-height;
    .menu-item {
      svg {
        $size: $font-size + $border-width;
        width: $size;
      }
      a {
        transition: all .15s ease;
        &:hover {
          color: $light-color;
        }
      }
    }
  }
  .main-nav {
    background-color: $light-color;
    box-shadow: 8px 1px 10px rgba(0, 0, 0, .2);
    height: $header-height;
    padding: 0 $layout-spacing-lg;
    z-index: $zindex-1;
    .avatar {
      background-color: $border-color;
      border-color: $border-width solid $gray-color;
      padding: $border-width;
    }
    .logo {
      height: 1.05rem;
    }
    .column {
      align-self: center;
    }
    .header-label {
      font-size: $font-size;
    }
    .header-title {
      color: $secondary-color;
      font-size: $font-size;
      font-weight: bold;
      margin-top: calc($layout-spacing-sm / 2);
    }
    .divider-vert {
      border-color: #D8D8D8;
      padding: 0 $layout-spacing;
      &::before {
        top: 0;
        bottom: 0;
        transform: translateX(0);
      }
    }
    .btn-menu-action {
      color: $gray-color-dark;
      height: auto;
      margin: 0 $layout-spacing;
      padding: 0;
      svg {
        font-size: 1rem;
        &.fa-search {
          font-size: .95rem;
        }
      }
      &:focus, &:active {
        box-shadow: none;
      }
      &.badge {
        color: $info-color;
        &::after {
          animation: blink-animation 1.2s infinite;
          background-color: $warning-color;
          height: $layout-spacing;
          right: 2px;
          top: 4px;
          width: $layout-spacing;
        }
      }
    }
  }
  .notification-bar {
    align-items: center;
    background-color: $red-color-500;
    color: $light-color;
    display: flex;
    font-weight: 600;
    height: $notification-bar-height;
    justify-content: center;
    padding: 0 $layout-spacing-lg;
  }
  .primary {
    display: grid;
    grid-template-columns: $sidebar-width auto;
    width: 100%;
    min-height: 1px;
    .side-menu {
      @media screen and (max-width: $size-sm) {
        display: none;
      }
      background-color: $light-color;
      border-right: $border-width solid $border-color;
      border-top: $border-width solid rgba(0, 0, 0, .05);
      padding-left: $layout-spacing;
      padding-right: $layout-spacing;
      position: relative;
      width: $sidebar-width;
      nav {
        text-align: center;
        a {
          color: $dark-color;
          transition: color .15s ease;
          &:hover, &:focus {
            box-shadow: none;
            text-decoration: none;
          }
        }
        .side-menu-icon {
          margin-top: $layout-spacing-lg;
          svg {
            height: $menu-icon-size;
            width: $menu-icon-size;
          }
        }
        .side-menu-label {
          display: block;
          font-size: $font-size-sm;
          font-weight: 500;
          text-align: center;
        }
        .router-link-exact-active {
          color: $primary-color;
          & + svg {
            color: $primary-color;
          }
        }
      }
      .logo-footer {
        bottom: 0;
        position: absolute;
        left: 0;
        padding: $layout-spacing;
        width: 100%;
        z-index: 1;
        small {
          font-size: 9px;
          color: #aaa;
          text-align: right;
          display: block;
          margin-bottom: -.1rem;
          z-index: 2;
        }
        img {
          display: block;
          width: 100%;
        }
        a {
          &:hover, &:focus {
            box-shadow: none;
            text-decoration: none;
          }
        }
      }
    }
    .main {
      flex: 1;
      overflow-y: auto;
      & > .forbidden-state {
        padding: $layout-spacing-lg;
      }
    }
  }
}

#branch-modal {
  .modal-container {
    padding: 0;
  }
  .branches {
    .active {
      .avatar {
        background-color: $primary-color;
      }
    }
  }
  .tile {
    background-color: $bg-color;
    border-radius: $border-radius;
    margin-bottom: $layout-spacing;
    padding: $layout-spacing;
    &:last-child {
      margin-bottom: 0;
    }
    .tile-icon {
      margin: 0 $layout-spacing-sm;
    }
  }
  .avatar {
    background-color: $gray-color;
    border: $border-width solid $border-color;
    height: 1.8rem;
    width: 1.8rem;
  }
  .tile-title {
    font-size: $font-size-lg;
    line-height: normal;
  }
  .tile-subtitle {
    color: $gray-color;
    font-size: $font-size-sm;
    line-height: normal;
    margin-top: $layout-spacing-sm;
  }
}
</style>
